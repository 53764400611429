import React, { useState } from "react"
import Img from "gatsby-image"

import Pontuation from "../Pontuation/Pontuation"

import styles from "./styles.module.css"
import ReactMarkdown from "react-markdown"

const Accordeon = ({ title, description, image }) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      className={`${styles.accordeon} ${open ? styles.open : ""}`}
      onClick={() => setOpen(!open)}
      onKeyPress={() => {}}
      role="button"
    >
      <h1 className={styles.title}>
        {title}
        <Pontuation>?</Pontuation>
      </h1>
      {open && <Img fixed={image.fixed} className={styles.image} />}
      {open && (
        <ReactMarkdown className={styles.description} source={description} />
      )}
    </div>
  )
}

export default Accordeon
