import React from "react"
import Img from "gatsby-image"

import SimpleLayout from "../Layout/SimpleLayout"
import Pontuation from "../Pontuation/Pontuation"
import Accordeon from "../Accordeon/Accordeon"

import styles from "./styles/faq.module.css"

const FAQs = ({ page, headerImg, accordeonImg, ...props }) => {
  return (
    <SimpleLayout path={props.path}>
      <header className={styles.header}>
        <div className={styles.container}>
          <h1 className={styles.sectionTitle}>
            {page.title}
            <Pontuation>.</Pontuation>
          </h1>
          <h2 className={styles.sectionDescription}>{page.subtitle}</h2>
        </div>
        <Img fixed={headerImg.fixed} className={styles.headerImg} />
      </header>
      <section className={styles.faqs}>
        <div className={styles.container}>
          {page.faqs.map(faq => (
            <Accordeon
              key={faq.title}
              title={faq.title}
              description={faq.description}
              image={accordeonImg}
            />
          ))}
        </div>
      </section>
    </SimpleLayout>
  )
}

export default FAQs
